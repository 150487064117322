.filterSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filterSection button {
  text-transform: uppercase;
  text-decoration: none;
  color: #009ee3;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
}

.filterSection button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 50%;
  border-bottom: 2px solid #009ee3;
  transition: width 0.2s ease;
}

.filterSection button:hover::after {
  width: 100%;
}

.categoryItem {
  box-shadow: white;
  border: none;
}

.filterHeading {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.filterCategories p {
  font-size: 14px;
  padding-bottom: 10px;
  cursor: pointer;
}

/* Filter Color */

.filterColorBtn {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.filterColorBtn button {
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  border: none;
  position: relative;
}

.filterColorBtn button.selected::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 50%;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  left: -6px;
  top: -6px;
  transition: all 0.3s ease;
}

/* filter Sizes */

.sizeButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.sizeButton {
  background-color: white;
  padding: 6px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 14px;
}

.sizeButton:hover {
  background-color: #e4e4e4;
}

.sizeButton.selected {
  background-color: #e4e4e4;
}

/* filter brands */

.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.searchIcon {
  position: absolute;
  right: 10px;
}

.searchBar input {
  width: calc(100% - 30px);
  padding: 12px 18px 9.6px;
  padding-right: 30px;
  border: 2px solid #e4e4e4;
  outline: none;
  font-size: 14px;
  text-transform: uppercase;
}

.brandList {
  margin-top: 10px;
}

.brandItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.brandRadio {
  margin-right: 15px;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #e4e4e4;
  position: relative;
  cursor: pointer;
  outline: none;
}

.brandRadio:checked {
  border: 2px solid #000000;
}

.brandRadio::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.brandRadio:checked::after {
  transform: translate(-50%, -50%) scale(1);
}

.notFoundMessage {
  font-size: 14px;
  color: red;
  margin-top: 10px;
}

.brandLabel {
  cursor: pointer;
  font-size: 14px;
}

.brandCount {
  margin-left: auto;
  font-size: 14px;
  color: #767676;
}

/* Filter Slider */

.priceRange {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.priceRange p {
  font-size: 14px;
  color: #767676;
  font-weight: 400;
}

.priceRange p span {
  color: black;
}
