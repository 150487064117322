section.my-account.container {
  padding: 30px;
  padding-left: 160px;
}
.my-account .page-title {
  font-size: 2.1875rem;
  font-weight: 700;
  text-transform: uppercase;
}
.my-account .account-nav {
  list-style: none;
  padding: 0;
  padding-top: 2.5rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
}
.my-account .account-nav .menu-link.menu-link_active {
  pointer-events: none;
  color: #c32929;
}
.my-account .page-content {
  padding-top: 2.5rem;
}
.my-account__dashboard p {
  width: 43.125rem;
  max-width: 100%;
}
.my-account__dashboard .unerline-link {
  text-decoration: underline;
}
.my-account__orders-list {
  overflow-x: auto;
  width: 100%;
}
.my-account .orders-table {
  border: 1px solid #e4e4e4;
  width: 100%;
}
.my-account .orders-table thead th {
  background-color: #e4e4e4;
  padding: 1rem 1.875rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
}
.my-account .orders-table tbody td {
  border-top: 1px solid #e4e4e4;
  padding: 1.875rem;
}
.my-account .orders-table tbody td:first-child {
  text-decoration: underline;
}
.my-account .orders-table tbody td:last-child {
  width: 12.5rem;
}
.my-account .orders-table tbody td .btn {
  height: 3.125rem;
  font-size: 0.875rem;
}
.my-account__address .notice {
  margin-bottom: 3.75rem;
}
.my-account__address-list {
  display: flex;
  gap: 5.625rem;
}
@media (max-width: 767.98px) {
  .my-account__address-list {
    flex-direction: column;
  }
}
.my-account__address-item {
  flex-grow: 1;
}
.my-account__address-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 0.875rem;
  font-weight: 500;
}
.my-account__address-item__title h5,
.my-account__address-item__title .h5 {
  font-size: 1.125rem;
  margin: 0;
}
.my-account__address-item__title a {
  font-size: 0.8125rem;
  border-bottom: 2px solid;
}
.my-account__address-item__detail {
  line-height: 1.5rem;
}
.my-account__address-item__detail p {
  margin: 0;
}
.my-account__edit .btn-primary {
  width: 18.75rem;
  height: 3.75rem;
  max-width: 100%;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.my-account__wishlist .btn-remove-from-wishlist {
  position: absolute;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 2;
  border: 0;
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.3s ease;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
}
.my-account__wishlist .product-card:hover .btn-remove-from-wishlist {
  opacity: 1;
  visibility: visible;
}
.menu-link {
  display: inline-block;
  position: relative;
  padding: 0.5em 0;
  color: #222222;
  line-height: 1.5em;
  text-decoration: none;
}

.menu-link_us-s:after {
  content: "";
  display: block;
  position: absolute;
  top: 2em;
  left: 0;
  width: 0;
  height: 2px;
  transition: width 0.28s cubic-bezier(0.47, 0, 0.745, 0.715);
  background-color: currentColor;
}

.menu-link_us-s:hover:after,
.menu-link_us-s.menu-link_active:after {
  width: 2em;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}

.orders-table .btn-primary {
  color: #fff;
  background-color: #222222;
  box-shadow: none;
  width: 100%;
  cursor: pointer;
}

.order-details-table tr{
  text-align: center;
}