.productSection {
  padding: 0 160px;
}

.productShowCase {
  display: flex;
  gap: 60px;
}

.productGallery {
  display: flex;
  gap: 10px;
  width: 50%;
}

.productThumb {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.productThumb img {
  width: 80px;
  height: 80px;
}

.productFullImg {
  position: relative;
  width: 100%;
  height: 520px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.productFullImg img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  pointer-events: none;
  background-color: #767676;
}

.buttonsGroup {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  width: 97%;
}

.directionBtn {
  background-color: white;
  border: none;
  display: flex;
  border-radius: 50%;
  padding: 13px;
  outline: none;
}

.directionBtn:hover {
  background-color: rgb(239, 239, 239);
}

/* Product Details CSS */

.productDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  gap: 10px;
}

.productBreadcrumb {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.breadcrumbLink {
  display: flex;
  align-items: center;
}

.breadcrumbLink a {
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  position: relative;
}

.breadcrumbLink a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.breadcrumbLink a:hover::after {
  width: 50%;
}

.prevNextLink {
  display: flex;
  align-items: center;
  gap: 25px;
}

.prevNextLink a {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  position: relative;
}

.prevNextLink a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.prevNextLink a:hover::after {
  width: 50%;
}

.productName h1 {
  font-size: 28px;
  font-weight: 500;
  color: black;
  line-height: 35px;
}

.productRating {
  display: flex;
  gap: 5px;
  align-items: center;
}

.productRating p {
  font-size: 14px;
  color: #5f5e5e;
  margin-left: 10px;
}

.productPrice h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #009ee3;
}

.productDescription p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 20px;
}

.productSizeColor {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
}

.productSize {
  display: flex;
  gap: 20px;
  align-items: center;
}

.productSize p {
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
}

.sizeBtn {
  display: flex;
  gap: 10px;
}

.sizeBtn button {
  background-color: white;
  border: 1px solid;
  padding: 5px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.productColor {
  display: flex;
  gap: 20px;
  align-items: center;
}

.colorBtn {
  display: flex;
  gap: 15px;
}

.colorBtn button {
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  position: relative;
}

.colorBtn button.highlighted::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  left: -6px;
  top: -6px;
}

.productColor p {
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
}

.productCartQuantity {
  display: flex;
  gap: 20px;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 20px;
}

.productQuantity {
  display: flex;
  border: 2px solid #e0e0e0;
  padding: 0 10px;
  max-width: 100px;
}

.productQuantity button {
  background-color: white;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 30px;
}

.productCartQuantity .btn-danger {
  background-color: white !important;
  border: 1px solid #d9534f;
  color: #d9534f;
  cursor: not-allowed;
}

.productQuantity input {
  width: 40px;
  height: 45px;
  text-align: center;
  border: none;
  outline: none;
  font-size: 20px;
}

.productCartBtn button {
  padding: 20px 110px;
  background-color: black;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.productWishShare {
  display: flex;
  gap: 30px;
  margin-bottom: 25px;
}

.productWishList button {
  display: flex;
  align-items: center;
  background-color: white;
  border: none;
  gap: 10px;
  outline: none;
  position: relative;
}

.productWishList button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.productWishList button:hover::after {
  width: 50%;
}

.productWishList button p {
  text-transform: uppercase;
  font-weight: 500;
}

.productShare {
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  position: relative;
}

.productShare::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 0;
  border-bottom: 2px solid black;
  transition: width 0.3s ease;
}

.productShare:hover::after {
  width: 50%;
}

.productTags {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.productTags p {
  font-size: 16px;
  color: black;
}

.productTags span {
  font-size: 16px;
  color: #767676;
}

.productVariant {
  width: 100%;
}

/* Responsive CSS */

@media screen and (max-width: 1250px) {
  .productCartBtn button {
    padding: 20px 40px;
  }
  .productFullImg img {
    width: 450px;
    height: 450px;
  }
}

@media screen and (max-width: 1210px) {
  .productSection {
    padding: 0 60px;
  }

  .productShowCase {
    display: flex;
    gap: 30px;
  }

  .productFullImg img {
    width: 420px;
    height: 420px;
    pointer-events: none;
  }

  .productCartBtn button {
    padding: 20px 80px;
  }
}

@media screen and (max-width: 991px) {
  .productShowCase {
    flex-direction: column;
    gap: 30px;
  }

  .productGallery {
    flex-direction: column-reverse;
  }

  .productThumb {
    flex-direction: row;
  }

  .buttonsGroup {
    top: 47%;
    left: 50%;
  }

  .productFullImg img {
    width: 100%;
    height: auto;
  }

  .productDetails {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .productSection {
    padding: 0 15px;
  }

  .productGallery {
    gap: 5px;
  }

  .productThumb img {
    width: 75px;
    height: 75px;
  }

  .buttonsGroup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .directionBtn {
    padding: 10px;
  }

  .productCartBtn button {
    padding: 20px 50px;
  }
}

@media screen and (max-width: 320px) {
  .productThumb img {
    width: 65px;
    height: 65px;
  }

  .productCartBtn button {
    padding: 20px 30px;
  }

  .sizeBtn button {
    padding: 5px 12px;
  }
}
